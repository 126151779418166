import type { PageProps } from 'gatsby'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import Breadcrumb from 'src/components/sections/Breadcrumb'
import InstitutionalText from 'src/components/sections/InstitutionalText'
import Newsletter from 'src/components/sections/Newsletter'
import Faq from 'src/components/common/Institutional/Faq'

const FAQ: FCC<PageProps> = (props) => {
  const {
    location: { host, pathname },
  } = props

  const title = 'FAQ | Miess'

  const docTitle = 'FAQ'

  const slug = 'institucionais/AntesCompra/faq'

  const description =
    'Encontre as melhores Lingeries e Produtos de Sex Shop Atacado e Varejo. Conheça a loja mais completa de produtos eróticos do Brasil.'

  const canonical =
    host !== undefined
      ? `https://${host}/${slug}`
      : slug
      ? `/${slug}`
      : pathname

  const cmsInfo = [
    {
      question: '<h3 class="active">1. Como se tornar um Revendedor(a)?</h3>',
      response:
        '<p>Somos uma distribuidora de lingerie e produtos sensuais, trabalhamos com preços de atacado, todos os valores ficam disponíveis diretamente no site.</p>\n            <p>Para se tornar um revendedor (a) Miess é bem simples!</p>\n            <p>Basta acessar o nosso site e realizar seu cadastro: <a href="https://www.miess.com.br/login">clique\n                    aqui</a>\n            </p>\n            <p>Pronto, a partir de agora você já pode dar continuidade em suas compras.</p>',
    },
    {
      question: '<h3>3. Possui catálogo virtual?</h3>',
      response:
        '<p>Sim, acesse o link:</p>\n            <p><a href="https://blog.miess.com.br/2019/Catalogo2019/Catalogo_Meu_Mundo_M_2019.pdf">https://blog.miess.com.br/2019/Catalogo2019/Catalogo_Meu_Mundo_M_2019.pdf</a>\n            </p>\n            <p>O catálogo possui valores sugeridos para revenda, o seu custo será sempre o valor do site.</p>',
    },
    {
      question:
        '<h3>4. Quantas peças ou valor é necessário para obter os preços de atacado?</h3>',
      response:
        '<p>Buscando uma parceria de sucesso com nossos revendedores, possuímos o valor mínimo para compras de R$20,00.\n            </p>',
    },
    {
      question:
        '<h3>5. Quais informações do site podem ser utilizadas em minhas mídias sociais e site?</h3>',
      response:
        '<p>Você pode utilizar nossas postagens do Instagram, Facebook e Banners. Desde modo, as descrições do site, não poderão ser utilizadas e também reaproveitadas em seu site.   </p>',
    },
    {
      question: '<h3>6. Como funciona a sacola de compras?</h3>',
      response:
        '<p>Na página inicial do site você visualiza o valor destacado em rosa, este já está com 25% de desconto, que é liberado nos pagamentos à vista (depósito ou boleto bancário).</p>\n            <p>Quando você clica na foto do produto visualiza o valor para depósito/boleto com 25% de desconto e o valor total a prazo.</p>\n            <p>Ao clicar em comprar, o produto é direcionado para sua sacola com o valor à vista em rosa, parcelado em 10X e o valor a prazo. Ao selecionar a forma de pagamento desejada, o site aplicará automaticamente o desconto correspondente.\n            </p>',
    },
    {
      question: '<h3>7. Quais são as formas de pagamento oferecidas?</h3>',
      response:
        '<p><b>Formas de Pagamento:</b><br>\n                Á vista: Boleto Bancário - Transferência - Pix.<br>\n                Opções de Banco: Bradesco - Santander - Caixa Banco do Brasil e Itaú.</p>\n\n            <p><b>Parcelado:</b> Cartão de Crédito em até 10x sem juros <br>\n                Bandeiras: Visa - Master - Elo<br>\n                PAYPAL - Aceita todas as bandeiras de cartão de crédito</p>',
    },
    {
      question:
        '<h3>8. Quantos dias tenho para efetuar o pagamento do meu pedido?</h3>',
      response:
        '<p>Após o fechamento do pedido, o mesmo permanece ativo *aguardando pagamento* por 1 dia útil (depósito e boleto bancário).Caso o pagamento não seja identificado, o pedido será automaticamente cancelado. Todo pedido feito com cartão de crédito, passa por uma consulta pela nossa instituição financeira Clear Sale, para que seja feita a análise de risco, e o pedido pode ser aprovado em até 72 horas úteis.</p>',
    },
    {
      question: '<h3>9. Como funcionam os descontos da loja?</h3>',
      response:
        '<p>O nosso desconto é progressivo, quanto maior o pedido maior será o desconto.</p>\n            <p>Depósito ou Boleto.</p>\n            <p><b>25%</b> - Valor em rosa disponível no site<br>\n                <b>30%</b> - Válido para pedidos acima de R$ 1.000,00<br>\n                <b>40%</b> - Válido para pedidos acima de R$ 1.800,00</p>\n\n            <p><b>Obs:</b> O valor em rosa no site, já está com 25% aplicado, válido somente para pagamento á vista, o desconto será sempre aplicado no valor bruto do produto (valor a prazo).</p>',
    },
    {
      question: '<h3>10. Possui loja física?</h3>',
      response:
        '<p>A Miess não possui loja física.</p>\n            <p>O pedido é feito diretamente no site e pode ser finalizado com a opção de <strong>Drive Thru</strong> na <strong>Rua Cavour, 708 - Vila Prudente</strong>.\n<br><br>\n                ❌ Seguindo os protocolos de segurança, estamos atendendo apenas <strong>drive-thru</strong> 🚘🏍️. Após receber o e-mail de pedido aprovado, entre em contato com nossa equipe e faça o <strong>agendamento</strong> por telefone (11) 4810-6810 com o SAC.\n<br><br>\n✔️ Venha de carro até o nosso escritório no horário agendado usando máscara, assim que chegar, <strong>ligue para a Recepção (11) 4810-6819</strong>, solicite o seu pedido e aguarde em uma de nossas vagas de estacionamento sem sair do carro.\n<br><br>\n✔️ Sua compra será higienizada e entregue por uma de nossas colaboradoras, usando máscara para a segurança de todos.\n<br><br>\n<strong>Obs:</strong> Caso o pedido não tenha sido (Pago e Agendado) não será possível realizar a retirada e sujeito a indisponibilidade de dia e horário.\n<br><br>\n<strong>Horário de atendimento:</strong> Segunda a Sexta das 8h00 às 18h00, e aos Sábados das 8h00 às 12h00.\n<br><br>\n                - Sujeito a disponibilidade de datas e horários\n\n            </p>',
    },
    {
      question: '<h3>11. Qual valor do frete para minha cidade?</h3>',
      response:
        '<p>O frete é calculado automaticamente no site ao finalizar sua compra, após digitar o CEP para entrega, considerando:peso da mercadoria   agilidade do frete contratado   localidade.</p>',
    },
    {
      question: '<h3>12. Em quantos dias recebo o pedido em minha cidade?</h3>',
      response:
        'O prazo pra entrega do pedido, será sempre conforme a agilidade do frete contratado, na finalização da compra, este prazo já está considerando o prazo para separação (Miess) e o prazo dos Correios/transportadora.\n<br><br>\nAssim que o seu pedido for coletado, você receberá um e-mail automático informando o procedimento para acompanhar.\n<br><br>\nCorreios <strong>(PAC ou Sedex):</strong> Você receberá um código de rastreamento e vai acompanhar através do site dos correios:  <a href="https://www2.correios.com.br/sistemas/rastreamento/default.cfm ">clique aqui para acompanhar</a> .\n<br><br>\n<strong>Transportadoras:</strong> Você receberá um link e deverá digitar os dados de cadastro <strong>(CPF ou CNPJ)</strong> para acompanhar: <a href=" https://www.rastrearmeupedido.com.br/rastreamentoCpfCnpj.html?1600451201774&amp;ce=9,10,42,69,72">clique aqui</a>',
    },
    {
      question: '<h3>13. A embalagem é discreta?</h3>',
      response:
        '<p>Todas as caixas são enviadas de forma personalizada, com o nome da loja apenas: Miess. Não há nenhuma informação referente ao tipo de produto comercializado.</p>',
    },
    {
      question: '<h3>14. Ainda não recebi o meu pedido, o que fazer?</h3>',
      response:
        '<p>Caso ocorra atraso na entrega de sua encomenda por conta dos Correios/Transportadora, entre em contato com nossa equipe através do nosso canal de atendimento: sac@miess.com.br ou no telefone 11 4810-6810.<br><br>\n                É importante ressaltar que, devido a situação da pandemia (<strong>COVID-19</strong>) e manifestação dos caminhoneiros iniciada em 08/09/2021, podem ocorrer alterações no prazo de entrega ou atrasos por parte dos Correios e transportadora, <strong> sem aviso prévio</strong>, o que não implica na entrega do pedido.</p>',
    },
    {
      question:
        '<h3>15. Recebi produtos diferentes do comprado, o que fazer?</h3>',
      response:
        '<p>Caso você receba um produto diferente do adquirido durante a sua compra, você deve entrar em contato conosco no período de 48 horas a contar do recebimento do produto, através do e-mail sac@miess.com.br relatando o ocorrido, para que seja providenciada a substituição pelo produto correto.</p>',
    },
    {
      question:
        '<h3>16. Posso fazer alterações em um pedido já finalizado?</h3>',
      response:
        '<p> Após a finalização da compra, não é possível efetuar nenhum tipo de alteração ou inclusão, somente realizando um novo pedido. </p>',
    },
    {
      question:
        '<h3>17. Qual o prazo para retorno, de um e-mail ou solicitação enviada?</h3>',
      response:
        '<p>O prazo máximo para retorno é de até <strong>72 horas</strong>, conforme o nosso horário de atendimento.</p>',
    },
    {
      question: '<h3>18. Vocês trabalham com compra consignada?</h3>',
      response: '<p>Não, o pagamento é feito sempre de forma antecipada.</p>',
    },
    {
      question: '<h3>19. A empresa oferece frete grátis?</h3>',
      response:
        '<p>Oferecemos Frete Grátis </p>\n            <p>PAC para todo Brasil, em pedidos acima de R$ 250,00 pagos.<br>\n                SEDEX para SP, em pedidos acima de R$ 250,00 pagos.<br></p>\n            <p>Ao incluir o CEP em sua sacola de compras, o site irá apresentar todos os fretes, prazos e valores\n                disponíveis para o CEP.</p>',
    },
    {
      question: '<h3>20. Como funciona a troca?</h3>',
      response:
        '<p>As situações para trocas e devoluções de produtos são as seguintes:</p>\n            <p>- Produto avariado<br>\n                - Defeito<br>\n                - Erro operacional (inconformidade)<br>\n                - Incompatibilidade de produtos.</p>\n            <p>Em qualquer um dos casos mencionados, entre em contato conosco para que possamos lhe orientar como proceder. Em caso de defeito por favor nos comunique no prazo máximo de 7 dias corridos - encaminhar fotos para análise prévia.</p>',
    },
    {
      question:
        '<h3>21. Qual o prazo de arrependimento para as compras realizadas via internet ou telefone?</h3>',
      response:
        '<p>Quando a aquisição de produto ocorrer fora do estabelecimento comercial (por telefone, através de internet ou por outro meio similar) o consumidor tem o prazo de reflexão de 7 (sete) dias corridos, a contar da data do recebimento do produto, para desistência, de acordo com o artigo 49 do Código de Defesa do Consumidor. A contagem do prazo inicia-se a partir do dia imediatamente posterior à contratação ou recebimento do produto. A contagem não é interrompida nos finais de semana ou feriados.</p>',
    },
    {
      question: '<h3>22. Como receber amostra grátis?</h3>',
      response:
        '<p>No momento da finalização da compra, caso o pedido alcance o valor da promoção vigente no site, automaticamente aparecerá os produtos de brinde para sua escolha.</p>\n            <p>Se a promoção vigente for ativada somente com uso de <strong>cupom</strong>, não se esqueça de utiliza-lo, pois somente assim você conseguirá garantir seu queridinho.</p>\n            <p></p><strong>Importante:</strong> caso não escolha uma das opções de brinde, não será possível acrescentar posteriormente.<p></p>',
    },
    {
      question: '<h3>23. Como cobrir preços de concorrente?</h3>',
      response:
        '<p>O procedimento é simples, rápido e fácil!</p>\n            <p>Basta acessar o site, escolher os produtos de seu interesse e passar o número do pedido, informando os produtos e o concorrente. <br>\n                - Pedido mínimo para cobrir preços R$ 80,00.<br>\n                - O pedido irá para a analise e retornaremos o contato em até 1 dia útil, aguarde o retorno para efetuar o pagamento.</p>\n            <p>Não faça negócio, antes de nos consultar, aqui temos sempre o melhor negócio para você.</p>\n            <p><b>Obs:</b> Após alteração do valor, o pagamento será possível apenas através de Transferência.</p>\n            <p><b>Bancos:</b> Itaú - Bradesco - Caixa - Banco do Brasil - Santander.</p>\n            <p><b>Lembrando:</b> Todo processo de cobrir preço, é efetuado com o pedido ainda não pago e cobrimos o preço somente de um concorrente/ loja. Para todas as datas iremos considerar somente <strong>dias úteis</strong>. Deste modo, serão desconsiderados os <strong>finais de semana e feriados </strong>(São Paulo e demais estados) pois não haverá nenhum tipo de movimentação no pedido.</p>\n            <br>',
    },
    {
      question: '<h3>24. O site é seguro?</h3>',
      response:
        '<p>A Miess é uma distribuidora online muito bem conceituada e estamos no mercado há 12 anos, é possível acompanhar o pedido em todos os processos, desde a finalização da compra até mesmo após a postagem, onde é enviado o código de rastreamento de forma automática para que acompanhe também a entrega.</p>\n            <p>Você também pode acessar nossa página no Facebook, onde poderá verificar a experiências de compras de outros clientes, segue o link:</p>\n            <p><a href="https://pt-br.facebook.com/pg/miessloja/reviews/?ref=page_internal">https://pt-br.facebook.com/pg/miessloja/reviews/?ref=page_internal</a></p>',
    },
  ]

  return (
    <>
      {/* SEO */}
      <GatsbySeo
        title={title}
        description={description}
        canonical={canonical}
        language="pt-br"
        openGraph={{
          type: 'website',
          title,
          description,
        }}
      />

      <BreadcrumbJsonLd
        itemListElements={[
          {
            item: `/${slug}`,
            name: docTitle,
            position: 1,
          },
        ]}
      />

      {/* Sections */}

      <Breadcrumb
        name={docTitle}
        breadcrumbList={[
          {
            item: `/${slug}`,
            name: docTitle,
            position: 1,
          },
        ]}
      />
      <InstitutionalText
        headerImage={{
          alt: 'Dúvidas Frequentes',
          desktop: 'https://miess.vtexassets.com/arquivos/Banner-full-FAQ.png',
          mobile: 'https://miess.vtexassets.com/arquivos/Banner-mobile-FAQ.png',
        }}
      >
        <Faq questions={cmsInfo} />
      </InstitutionalText>
      <Newsletter />
    </>
  )
}

export default FAQ
